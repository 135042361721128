<template>
  <div class="material-container">
    <ion-list lines="none">
      <ion-item v-for="item in materiales" :key="item.id" class="material-item">
        <ion-label class="label-item">{{ item.name }}</ion-label>
        <ion-note slot="end">{{ item.cantidad }}</ion-note>
        <ion-button slot="end" fill="clear" size="small" @click="quitItem(item.id)">
          <ion-icon
            slot="icon-only"
            :icon="trashOutline"
            size="small"
          >
          </ion-icon>
        </ion-button>
      </ion-item>
    </ion-list>
  </div>
</template>

<script>
import { IonList, IonItem, IonLabel, IonNote,IonIcon, IonButton } from "@ionic/vue";
import { trashOutline } from "ionicons/icons";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "item-material-component",
  components: {
    IonList,
    IonItem,
    IonLabel,
    IonNote,
    IonIcon,
    IonButton
  },
  data() {
    return {
      trashOutline,
    };
  },
  computed: {
    ...mapGetters("item", ["materiales", ]),   
  },
  methods: {
    ...mapActions('item',["delItemMaterial"]),
    quitItem(id) {
      this.delItemMaterial(id);
    }
  }
};
</script>

<style scoped>
.material-container{ 
  border: 0.1em solid #ddd;
  margin: 0.2em;
  margin-left: 0;
  padding: 0;
  justify-content: left;
  overflow: auto;
}
.material-item{
  height: 36px;
}
.material-item div.item-inner {
    border-bottom: none;
  }
.material-item .label-item{
  width:90%;
  font-size: 14px;
}
.material-item .label-cantidad{
  width:10%;
  font-size: 14px;
}
ion-note{
  font-size:16px;
}
</style>