<template>
  <ion-item lines="inset">
    <ion-label position="floating">Personal</ion-label>
    <ion-select
      multiple="true"
      cancel-text="Cancelar"
      ok-text="Hecho"
      placeholder="Seleccione empleado"
      @ionChange="addPersonas"
      v-model="empleado"      
    >
      <ion-select-option
        v-for="item in personal"
        :key="item.id"
        :value="item.id"
        >{{ item.nombres }}</ion-select-option
      >
    </ion-select>
  </ion-item>
</template>

<script>
import {IonItem, IonLabel, IonSelect, IonSelectOption} from '@ionic/vue';
import { mapActions, mapGetters } from "vuex";
export default {
  name: "personal-select",
  data() {
    return {
      empleado:[]
    };
  },
  components: {IonItem, IonLabel, IonSelect, IonSelectOption},
  mounted(){
    this.retrievePersonal();
  },
  computed: {
    ...mapGetters("item", ["personal","empleados"]),
  },
  methods:{
    ...mapActions("item",["retrievePersonal", "setEmpleados"]),
    addPersonas(){
      const personas = [];
      this.empleado.map(p =>{
        const {id, identificacion, nombres } = this.personal.find(per => per.id === p);
        const persona = {
          id, identificacion, nombres
        }
        personas.push(persona);
      })
      this.setEmpleados(personas);
    }
  }
};
</script>

