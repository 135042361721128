<template>
  <ion-item class="auth-time">
    <ion-select
      v-model="dataEquipo.id"
      cancel-text="Cancelar"
      ok-text="Hecho"
    >
      <ion-select-option
        v-for="item in equipo"
        :key="item.id"
        :value="item.id"
        >{{ item.name }}</ion-select-option
      >
    </ion-select>
    <ion-input
      type="number"
      min="1"
      step="1"
      side="right"
      v-model="dataEquipo.cantidad"
    ></ion-input>
    <ion-button slot="end" fill="clear" color="success" @click="addEquipo"
      ><ion-icon  slot="icon-only" :icon="addCircleOutline" ></ion-icon
    ></ion-button>
  </ion-item>
</template>
<script>
import {
  IonItem,
  IonSelect,
  IonSelectOption,
  IonInput,
  IonButton,
  IonIcon,
  toastController
} from "@ionic/vue";
import { addCircleOutline } from "ionicons/icons";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "item-equipo-new",
  components: {
    IonSelect,
    IonSelectOption,
    IonInput,
    IonItem,
    IonButton,
    IonIcon,    
  },
  data() {
    return {
      addCircleOutline,
      dataEquipo: {
        id: null,
        equipo: null,
        cantidad: 1,
      },
      toatsMessage:'',
      color: ''
    };
  },
  created(){
    this.retrieveEquipos();
  },
  computed: {
    ...mapGetters("item", ["equipo", "itemEquipo"]),
  },
  methods: {
    ...mapActions("item", ["addEquipoItem", "retrieveEquipos", "retrieveEquipo"]),
    async addEquipo() {
        if(this.dataEquipo.id === null || this.dataEquipo.id === '' ){
          this.toatsColor = "warning";
          this.toatsMessage = `¡Debes seleccionar un item`;
          this.openToast();
        }else{
          this.retrieveEquipo(this.dataEquipo.id);
          this.dataEquipo.name = this.itemEquipo.name;
          this.addEquipoItem({item:this.dataEquipo});
        }
        this.dataEquipo.id = null;
        this.dataEquipo.cantidad = 1;
    },
    async openToast() {
      const openToast = await toastController.create({
        message: this.toatsMessage,
        position: "middle",
        color: this.toatsColor,
        duration: 2000,
      });
      return openToast.present();

    }
  },
};
</script>

<style scoped>
.auth-time div.item-inner {
  border-top: none;
}
.auth-time div.item-inner ion-input {
  max-width: 50px;
}
.auth-time div.item-inner ion-input input {
  text-align: right;
}
ion-select {
  width: 80%;
}
</style>