<template>
  <ion-item class="auth-time">
    <ion-select
      v-model="dataMaterial.id"
      cancel-text="Cancelar"
      ok-text="Hecho"
    >
      <ion-select-option
        v-for="item in material"
        :key="item.id"
        :value="item.id"
        >{{ item.name }}</ion-select-option
      >
    </ion-select>
    <ion-input
      type="number"
      min="1"
      step="1"
      side="right"
      v-model="dataMaterial.cantidad"
    ></ion-input>
    <ion-button slot="end" fill="clear" color="success" @click="addMaterial"
      ><ion-icon  slot="icon-only" :icon="addCircleOutline" ></ion-icon
    ></ion-button>
  </ion-item>
</template>
<script>
import {
  IonItem,
  IonSelect,
  IonSelectOption,
  IonInput,
  IonButton,
  IonIcon,
  toastController
} from "@ionic/vue";
import { addCircleOutline } from "ionicons/icons";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "item-material-new",
  components: {
    IonSelect,
    IonSelectOption,
    IonInput,
    IonItem,
    IonButton,
    IonIcon,    
  },
  data() {
    return {
      addCircleOutline,
      dataMaterial: {
        id: null,
        name: null,
        cantidad: 1,
      },
      toatsMessage:'',
      color: ''
    };
  },
  created(){
    this.retrieveMateriales();
  },
  computed: {
    ...mapGetters("item", ["material", "itemMaterial"]),
  },
  methods: {
    ...mapActions("item", ["addMaterialItem", "retrieveMaterial","retrieveMateriales"]),
    async addMaterial() {
        if(this.dataMaterial.id === null || this.dataMaterial.id === '' ){
          this.toatsColor = "warning";
          this.toatsMessage = `¡Debes seleccionar un item`;
          this.openToast();
        }else{
          this.retrieveMaterial(this.dataMaterial.id);
          this.dataMaterial.name = this.itemMaterial.name;
          this.addMaterialItem({item:this.dataMaterial});
        }
        this.dataMaterial.id = null;
        this.dataMaterial.cantidad = 1;
    },
    async openToast() {
      const openToast = await toastController.create({
        message: this.toatsMessage,
        position: "middle",
        color: this.toatsColor,
        duration: 2000,
      });
      return openToast.present();

    }
  },
};
</script>

<style scoped>
.auth-time div.item-inner {
  border-top: none;
}
.auth-time div.item-inner ion-input {
  max-width: 50px;
}
.auth-time div.item-inner ion-input input {
  text-align: right;
}
ion-select {
  width: 80%;
}
</style>